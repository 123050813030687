import { ColorModeScript } from '@chakra-ui/react'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import defaults from './common/defaults'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

const client = new ApolloClient({
	uri: defaults.api.graphql,
	cache: new InMemoryCache(),
})

ReactDOM.render(
	<StrictMode>
		<ColorModeScript initialColorMode={defaults.theme.mode} />
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
