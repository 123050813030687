export default {
	variants: {
		filled: () => ({
			field: {
				background: 'rgba(255, 255, 255, 0.12)',
				_focus: {
					borderColor: '#ff8ac091',
				},
			},
		}),
	},
}
