export default {
	baseStyle: {
		color: 'white',
	},
	variants: {
		inverted: () => ({
			color: 'black',
		}),
	},
}
